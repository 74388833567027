import React from "react";
import "./Home.scss";
import BannerComponent from "../BannerComponent/BannerComponent";
import garethPic from "../images/gareth.jpg";

const HomeComponent = () => {
  return (
    <div>
      <BannerComponent />
      <h2 className="main-header">Reliable support with a smile</h2>
      <div className="top-line"></div>
      <div className="home-container">
        <div id="about" className="image-wrap">
          <img src={garethPic} alt="Smiling man" />
        </div>
        <div className="about-wrap">
          <h3>An experienced Mental Health Nurse with passion for helping. </h3>
          <p>
            Hi, my name is Gareth, I am a currently practicing, NMC registered
            and DBS checked Mental Health Nurse. I've just started a new service
            in Bradford-on-Avon and surrounding areas offering befriending,
            companionship and day time respite for loved ones and carers. I aim
            to enable people to do the things they enjoy and remain independent
            with a view to improving their overall wellbeing.
          </p>
          <p>
            I've been a registered mental nurse since 2017 and have worked for
            the NHS on a dementia ward in Cornwall. More recently I have worked
            with community and specialist teams in Wiltshire and an older
            persons team in South Gloucestershire.
          </p>
          <p>
            I have enjoyed much of my nursing career so far but have lately seen
            such a great need for the best quality care in peoples own homes
            that I have made the leap and started my own small independent
            business.
          </p>
          <p>
            I will provide the best quality service to you or your loved one and
            always with a smile. 'Bradford On Avon Later Life Support' will be
            running from September 2023 . I am fully insured and my hourly rates
            are very competitive.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;

import React from "react";
import "./BannerComponent.scss";

const BannerComponent = () => {
  return (
    <section className="banner">
      <div className="container">
        <div className="banner-content">
          <h2>Bradford-on-Avon Later Life Support</h2>
          <h3>
            Providing a befriending, companionship and respite service in the
            Bradford-on-Avon area.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default BannerComponent;

import React from "react";
import "./NavBar.scss";
import logo from "../images/logo.jpg";

const NavBar = () => {
  return (
    <nav className="main-nav">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="nav-links">
        <ul>
          <li>
            {" "}
            <a href="#about">About</a>{" "}
          </li>
          <li>
            {" "}
            <a href="#services">Services</a>{" "}
          </li>
          <li>
            {" "}
            <a href="#contact">Contact</a>{" "}
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;

import React from "react";
import Home from "./HomePage/Home";
import NavBar from "./NavBar/NavBar";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";

const App = () => {
  return (
    <div>
      <NavBar />
      <Home />
      <Services />
      <Contact />
    </div>
  );
};

export default App;

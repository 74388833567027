import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.scss";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          alert(
            "Thanks for the message I will get back to you soon as possible"
          );
        },
        (error) => {
          console.log(error.text);
          alert(
            "Sorry, the contact form is currently unavailable. You can still contact me using the details provided."
          );
        }
      );
    e.target.reset();
  };
  return (
    <section id="contact" className="contact-section">
      <h2>Get in touch</h2>
      <div className="top-line"></div>
      <p>
        Submit a query using the form below or alternatively use the contact
        details provided. I will endeavour to get back to you as soon as
        possible.
      </p>
      <div className="contact-container">
        <div className="contact-details">
          <h4>Email</h4>
          <a href="mailto:garethboa@outlook.com ">garethboa@outlook.com</a>
          <h4>Phone</h4>
          <p>07760970280</p>
          <h4>Facebook</h4>
          <a href="https://www.facebook.com/profile.php?id=100095156203701">
            Click here for my facebook page
          </a>
        </div>
        <div className="form-container">
          <form onSubmit={sendEmail} ref={form}>
            <input
              type="text"
              placeholder="Your Name"
              name="user_name"
              required
            />
            <input
              type="email"
              placeholder="Email"
              name="user_email"
              required
            />
            <input type="text" placeholder="Subject" name="subject" required />
            <textarea
              name="message"
              placeholder="Your message here"
              cols="30"
              rows="10"
            ></textarea>
            <button className="btn" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

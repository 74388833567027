import React from "react";
import "./Services.scss";

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2>What do I offer ?</h2>
      <div className="top-line"></div>
      <div className="card-wrap">
        <div className="card">
          <h4>Respite for Carers</h4>
          <i class="fas fa-solid fa-hand-holding-heart fa-2x"></i>
          <p>
            Caring for loved ones can be equally rewarding and taxing
            experience. I am here to give offer a little bit of respite of
            carers so they can maintain their own health and wellbeing.
          </p>
        </div>
        <div className="card">
          <h4>Befriending</h4>
          <i class="fas fa-regular fa-handshake fa-2x"></i>
          <p>
            One of the most rewarding aspects of working in care has been
            developing therapeutic relationships. I love to have a chat, have a
            laugh and get people smiling.
          </p>
        </div>
        <div className="card">
          <h4>Independence and Wellbeing</h4>
          <i class="fas fa-rainbow fa-2x"></i>
          <p>
            My support will focus around instilling a sense of independence by
            helping people do what they love doing and promoting a positive
            sense of self.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Services;
